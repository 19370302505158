<template>
    <div>
        <page-header
            :show-close="true"
            @onClose="returnToRoot"
        >
            <template
                v-if="isIOS()"
                #right
            >
                <a :href="shareUrl">
                    <img
                        src="@/assets/ios-share-icon.svg"
                        alt="iOS Share Button"
                    >
                </a>
            </template>
        </page-header>
        <loading-indicator
            v-if="loading"
            :title="$t('global.loadingMessage.loading')"
        />
        <error-view
            v-if="errorText"
            :description="errorText"
            :cta="$t('global.cta.retry')"
        />
        <div
            v-else
            class="container"
        >
            <h5 class="mb-3">
                <span v-html="$t('pages.payItForwardContactList.title')" />
                <a
                    href="#"
                    @click="openInfoModal"
                >
                    <img
                        class="ms-1 align-top"
                        src="@/assets/info-icon.svg"
                        alt="Info Button"
                    >
                </a>
            </h5>
            <form-field
                name="search"
                class="mb-3"
                :placeholder="$t('pages.payItForwardContactList.searchPlaceholder')"
                v-model="searchFilter"
            />
            <div :class="searchFilter.length > 0 && 'expand-list'">
                <div
                    class="d-flex mb-2"
                    v-for="contact in contacts"
                    :key="contact.key"
                >
                    <div>
                        <div class="fw-bolder">
                            {{ contact.name }}
                        </div>
                        <div class="text-muted">
                            {{ contact.phoneNumber }}
                        </div>
                    </div>
                    <button
                        @click="shareInvite(contact.smsLink)"
                        class="button-wrapper ms-auto my-auto bg-black d-flex btn text-white py-0"
                    >
                        <div class="my-auto">
                            {{ $t('pages.payItForwardContactList.invite') }}
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <modal
            :show="showInfoModal"
            :title="$t('pages.payItForwardContactList.infoModalTitle')"
            @close="closeInfoModal"
        >
            <p
                class="text-center mb-3"
                v-html="$t('pages.payItForwardContactList.infoModalContent')"
            />
            <base-button @click="closeInfoModal">
                {{ $t('pages.payItForwardContactList.infoModalClose') }}
            </base-button>
        </modal>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import maxBy from 'lodash/maxBy'
    import compact from 'lodash/compact'
    import PageHeader from '@/components/PageHeader'
    import format from '@/mixins/format'
    import { i18n } from '@/utils/i18n'
    import ErrorView from '@/components/ErrorView'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { navigation } from '@/mixins/navigation'
    import FormField from '@/components/base/FormField'
    import { RoutePaths } from '../routes/router'
    import { pifNativeShareUrlString } from '@/utils/sharedLogic'
    import { isIOS } from '@/utils/parseUserAgents'
    import { DeviceCapabilities } from '@/utils/deviceCapabilities'
    import { PhoneLabel } from '@/data/constants'
    import BaseButton from '@/components/base/BaseButton'
    import Modal from '@/components/Modal'

    const sanitizePhoneNumber = (phoneNumber) => {
        return phoneNumber.replace(/[^0-9]/g, '')
    }

    const formatPhoneNumber = (phoneNumber) => {
        const sanitizedNumber = sanitizePhoneNumber(phoneNumber)

        // Format numbers in 123-4567 format
        if (sanitizedNumber.length === 7) {
            return `${sanitizedNumber.substring(0, 3)} - ${sanitizedNumber.substring(3, 7)}`
        }

        // Format numbers in (123)-456-7891 or 1(234)-567-8912 format
        if ([10, 11].includes(sanitizedNumber.length)) {
            const trimmedNumber = sanitizedNumber.substring(sanitizedNumber.length - 10)

            return `(${trimmedNumber.substring(0, 3)}) ${trimmedNumber.substring(3, 6)} - ${trimmedNumber.substring(6, 10)}`
        }

        // All other formats are returned as continuous strings like 12345678
        return sanitizedNumber
    }

    export default {
        name: 'PayItForward',
        components: {
            LoadingIndicator,
            PageHeader,
            ErrorView,
            FormField,
            Modal,
            BaseButton,
        },
        mixins: [format, navigation], //returnToRoot comes from navigation
        data: function () {
            return {
                payItForwardContactList: {
                    authorizationStatus: 'unknown',
                },
                searchFilter: '',
                showInfoModal: false,
            }
        },
        computed: {
            ...mapGetters(['isWebView', 'payItForwardShareLink', 'payItForwardMaxAmount', 'error', 'loading', 'deviceCapabilities', 'deviceInfo']),
            smsMessage: function () {
                const temp = this.$t('pages.payItForward.smsMessage', { maxAmount: this.toFormattedUSDNoCents(this.payItForwardMaxAmount), pifLink: this.payItForwardLink }).toString()
                return temp
            },
            errorText: function () {
                if (this.error) {
                    return i18n.t('pages.payItForward.getShareLinkError')
                }
                return null
            },
            contacts: function () {
                const contacts = this.payItForwardContactList?.contacts || []

                const formattedContacts = compact(
                    contacts.map((contact) => {
                        if (contact.phoneNumbers.length < 1) {
                            return null
                        }

                        // Assign a priority to a contact's phone numbers
                        // "main" is the highest priority followed by "mobile" and "iPhone"
                        // anything else has equal priority.
                        const prioritizedPhoneNumber = contact.phoneNumbers.map((number) => {
                            const priority = [PhoneLabel.IPhone, PhoneLabel.Mobile, PhoneLabel.Main].indexOf(number.label)

                            return {
                                priority,
                                phoneNumber: number.phoneNumber,
                            }
                        })

                        // Get highest priority phone number
                        const mainNumber = maxBy(prioritizedPhoneNumber, 'priority')

                        return {
                            firstName: contact.firstName,
                            lastName: contact.lastName,
                            fullName: `${contact.firstName} ${contact.lastName}`,
                            phoneNumber: sanitizePhoneNumber(mainNumber.phoneNumber),
                            phoneNumberFormatted: formatPhoneNumber(mainNumber.phoneNumber),
                        }
                    })
                )

                const filteredContacts = formattedContacts.filter((contact) => {
                    const normalizedFilter = this.searchFilter.trim().toLowerCase()

                    return (
                        normalizedFilter.length < 1 ||
                        contact.firstName.trim().toLowerCase().startsWith(normalizedFilter) ||
                        contact.lastName.trim().toLowerCase().startsWith(normalizedFilter) ||
                        contact.phoneNumber.trim().toLowerCase().startsWith(normalizedFilter)
                    )
                })

                const sortedContacts = filteredContacts.sort((a, b) => {
                    if (a.fullName.trim().length === 0) {
                        return 1
                    }

                    return a.fullName.localeCompare(b.fullName)
                })

                return sortedContacts.map((contact, index) => {
                    const copy = this.$t('pages.payItForward.smsMessage', {
                        phoneNumber: sanitizePhoneNumber(contact.phoneNumber),
                        maxAmount: this.toFormattedUSDNoCents(this.payItForwardMaxAmount),
                        pifLink: this.payItForwardShareLink,
                    }).toString()

                    let smsLink
                    const body = encodeURIComponent(copy)
                    if (this.deviceInfo.runtimeType === 'ios') {
                        smsLink = this.deviceCapabilities[DeviceCapabilities.ComposeSms] ? `/composeSms?phones=${contact.phoneNumber}&body=${body}` : `sms://${contact.phoneNumber}&body=${body}`
                    } else {
                        // composeSms requires update on android
                        smsLink = `sms:?body=${body}`
                    }

                    return {
                        key: index,
                        name: contact.fullName,
                        phoneNumber: contact.phoneNumberFormatted,
                        smsLink,
                    }
                })
            },
            shareUrl: function () {
                return pifNativeShareUrlString(this.payItForwardShareLink, this.payItForwardMaxAmount)
            },
        },
        mounted() {
            if (!isIOS() || !this.isWebView) {
                this.$router.replace({ path: RoutePaths.PAY_IT_FORWARD })
                return
            }

            window.loadContactPage = this.loadContactPage
            window.location.href = '/getContactList'
            this.getShareLink()
            this.$logEvent('view_pay_it_forward_contact_list')
        },
        methods: {
            isIOS: function () {
                return isIOS()
            },
            shareInvite: function (shareLink) {
                this.$logEvent('click_pay_it_forward_contact_list_invite')
                window.location.href = shareLink
            },
            getShareLink: async function () {
                if (this.payItForwardShareLink.length === 0) {
                    await this.$store.dispatch('getPayItForward')
                }
            },
            loadContactPage: function (json) {
                this.payItForwardContactList = JSON.parse(json)

                if (this.payItForwardContactList.authorizationStatus !== 'authorized') {
                    this.$router.replace(RoutePaths.PAY_IT_FORWARD)
                }
            },
            openInfoModal: function () {
                this.showInfoModal = true
            },
            closeInfoModal: function () {
                this.showInfoModal = false
            },
        },
    }
</script>
<style lang="scss" scoped>
    .button-wrapper {
        height: 2rem;
        border-radius: 1rem;
    }

    // HACK temporarily fixes an issue where contacts hide in phones with
    // short screens
    .expand-list {
        height: 100vh;
    }
</style>
